/**
 * Returns value from languageMap object
 * @param languageMap the source object with languageMap
 * @param locale language name 'ru', 'en' e.t.c
 *
 * @example
 * const object = { 'ru': 'Локомотив Москва', 'en': 'Lokomotiv Moscow', 'kz': 'Lokomotiv Moscow' };
 * parseLanguageMap(object, 'ru') // 'Локомотив Москва'
 */

export function parseLanguageMap(
  languageMap: Record<string, string> | string,
  locale: string,
): string {
  if (typeof languageMap === 'string') return languageMap

  return (
    languageMap[locale] ?? languageMap['en'] ?? Object.values(languageMap)[0]
  )
}
